import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneNumber.scss";
interface IPhoneTextInput {
  onChange: any;
  name: string;
  value: any;
  error: any;
  countryCode?: any;
}
const PhoneNumberInput = ({
  onChange,
  name,
  value,
  error,
  countryCode,
}: IPhoneTextInput) => {
  const handleChange = (e: any) => {
    onChange({ target: { value: e, name } });
  };
  return (
    <div className={error ? "errorPhoneInputWrapper" : "phoneInputWrapper"}>
      <PhoneInput
        country={"ca"}
        value={value}
        onChange={handleChange}
        inputStyle={{
          width: "100%",
          height: "56px",
          borderRadius: "30px",
          background: "rgba(21, 21, 21, 1)",
          border: error
            ? "1px solid rgb(241, 37, 37)"
            : "1px solid rgba(77, 77, 77, 1)",
          color: error ? "rgb(241, 37, 37)" : "white",
          fontSize: "14px",
          paddingLeft: "70px",
          outline: "none",
        }}
        placeholder="Phone Number"
      />
      {error && <div className={"phone-input-error-message"}>{error}</div>}
    </div>
  );
};

export default PhoneNumberInput;
