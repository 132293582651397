import React from "react";

const PlayIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10002 11.5C0.898716 11.5 0.698513 11.445 0.521409 11.3361C0.362099 11.2372 0.230619 11.0994 0.139386 10.9356C0.0481538 10.7718 0.000181338 10.5875 0 10.4V1.59982C0 1.21812 0.198004 0.863909 0.521409 0.663706C0.681072 0.565541 0.863077 0.509595 1.05031 0.501128C1.23755 0.492662 1.42386 0.531952 1.59173 0.615305L10.3919 5.01538C10.5746 5.10691 10.7282 5.24747 10.8356 5.42134C10.943 5.59522 10.9999 5.79554 10.9999 5.9999C10.9999 6.20426 10.943 6.40458 10.8356 6.57846C10.7282 6.75233 10.5746 6.8929 10.3919 6.98442L1.59173 11.3845C1.43773 11.4615 1.26722 11.5 1.10002 11.5Z"
        fill="url(#paint0_linear_786_2830)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_786_2830"
          x1="0"
          y1="0.5"
          x2="10.9999"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4136" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlayIcon;
