import { useLocation, useNavigate } from "react-router-dom";

const useGetQueryParams = (pageAndSearchIncluded = true) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const getQuery = (name: any) => new URLSearchParams(search).get(name);

  const updateOrAddSearchParams = (obj: any) => {
    const searchParams: any = new URLSearchParams(window.location.search);
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const result = [
        "status",
        "lowerAmount",
        "higherAmount",
        "specificAmount",
        "startDate",
        "endDate",
        "amount0ption",
        "propertyType",
        "productType",
      ].includes(key)
        ? JSON.stringify(value)
        : value;
      const encodedValue = encodeURIComponent(result);
      searchParams.set(key, encodedValue);
    });
    // remove refresh from search params
    searchParams.delete("refresh");
    // push new search params to history
    if (pageAndSearchIncluded) {
      searchParams.set("page", 1);
      searchParams.set("search", "");
    }
    navigate({
      search: searchParams.toString(),
    });
  };

  const getSearchParams = (key: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    const result = searchParams.get(key);
    if (!result) return "";
    const decodedResult = decodeURIComponent(result);
    const output = decodedResult;
    return output;
  };

  const getAllFilterQueries = () => {
    const propertyTypeParams = [
      "oneBedroomApartment",
      "twoBedroomApartment",
      "threeBedroomApartment",
      "entireSuite",
      "sharedApartment",
      "studioApartment",
    ];

    const propertyTypes = [
      "onebedroomapartment",
      "twobedroomapartment",
      "threebedroomapartment",
      "entiresuite",
      "sharedapartment",
      "studioapartment",
    ];

    const productTypeParams = [
      "rentPayment",
      "rentGuarantee",
      "preRentGuarantee",
      "apartmentSearch",
    ];

    const productTypesArr = [
      "rentpayment",
      "rentguarantee",
      "prerentguarantee",
      "apartmentsearch",
    ];

    // Reduce to map propertyTypeParams to lowercase propertyTypes in getSearchParams and return boolean
    const propertyType = propertyTypeParams.reduce((acc: any, param, index) => {
      acc[param] = getSearchParams(propertyTypes[index]) === "true";
      return acc;
    }, {});

    const productType = productTypeParams.reduce((acc: any, param, index) => {
      acc[param] = getSearchParams(productTypesArr[index]) === "true";
      return acc;
    }, {});

    const result = {
      status: getSearchParams("status") || "",
      lowerAmount: getSearchParams("loweramount") || "",
      higherAmount: getSearchParams("higheramount") || "",
      specificAmount: getSearchParams("specificamount") || "",
      startDate: getSearchParams("startdate") || "",
      endDate: getSearchParams("enddate") || "",
      amountOption: getSearchParams("amountoption") || "",
      propertyType,
      productType,
    };

    return result;
  };

  return {
    getQuery,
    updateOrAddSearchParams,
    getSearchParams,
    search,
    getAllFilterQueries,
  };
};

export default useGetQueryParams;
