import React from "react";

const StopIcon = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_159_874)">
        <path
          d="M5.5 0.5C2.46262 0.5 0 2.96262 0 6C0 9.03738 2.46262 11.5 5.5 11.5C8.53738 11.5 11 9.03738 11 6C11 2.96262 8.53738 0.5 5.5 0.5ZM5.5 10.8125C2.84625 10.8125 0.6875 8.65375 0.6875 6C0.6875 3.34625 2.84625 1.1875 5.5 1.1875C8.15375 1.1875 10.3125 3.34625 10.3125 6C10.3125 8.65375 8.15375 10.8125 5.5 10.8125ZM6.875 3.9375H4.125C3.94266 3.9375 3.7678 4.00993 3.63886 4.13886C3.50993 4.2678 3.4375 4.44266 3.4375 4.625V7.375C3.4375 7.55734 3.50993 7.7322 3.63886 7.86114C3.7678 7.99007 3.94266 8.0625 4.125 8.0625H6.875C7.05734 8.0625 7.2322 7.99007 7.36114 7.86114C7.49007 7.7322 7.5625 7.55734 7.5625 7.375V4.625C7.5625 4.44266 7.49007 4.2678 7.36114 4.13886C7.2322 4.00993 7.05734 3.9375 6.875 3.9375Z"
          fill="url(#paint0_linear_159_874)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_159_874"
          x1="0"
          y1="0.5"
          x2="11"
          y2="0.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF4136" />
          <stop offset="1" stopColor="#FBB040" />
        </linearGradient>
        <clipPath id="clip0_159_874">
          <rect
            width="11"
            height="11"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StopIcon;
