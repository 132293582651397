import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlayIcon from "../../../../assets/svgs/PlayIcon";
import StopIcon from "../../../../assets/svgs/StopIcon";
import Button from "../../../../components/appButton";
import TextInput from "../../../../components/TextInput";
import AudioTrimmerIncrementAndDec from "./AudioTrimmerIncrementAndDec";
import Styles from "./index.module.scss";
import { useAppSelector } from "../../../../redux/reducers/hooks";
import { formatToTimeString } from "../../../../utilities/helpers/decimalPartical";

interface IAudioWaveform {
  url: string;
  handleSubmitAudoji: any;
  createAudojiLoader: boolean;
  title?: string;
  lyricToEdit?: string;
  resetLyricsForm?: any;
}
const AudioWaveform = ({
  url,
  handleSubmitAudoji,
  createAudojiLoader,
  title,
  lyricToEdit,
  resetLyricsForm,
}: IAudioWaveform) => {
  const { isRefetchAudoji } = useAppSelector((state) => state?.uploadModal);
  const audioRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(10); // Default end time
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isDraggingStart, setIsDraggingStart] = useState(false);
  const [isDraggingEnd, setIsDraggingEnd] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(1000); // Default canvas width
  const [canvasHeight, setCanvasHeight] = useState(50); // Default canvas height
  const [isUseDragEffect, setIsUseDragEffect] = useState(false);

  // const [loading, setLoading] = useState(false);

  // Adjust canvas size based on window resizing
  useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current) {
        setCanvasWidth(canvasRef.current.offsetWidth); // Get the width of the parent container
        setCanvasHeight(canvasRef.current.offsetWidth * (50 / 1000)); // Maintain aspect ratio
      }
    };

    // Call the function initially and also on window resize
    handleResize(); // Call once on mount to set initial size
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  // Load the audio and generate the waveform data
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
        setEndTime(audio.duration); // Set end time to the full duration
      };
    }
  }, []);

  // Play/Pause audio based on the isPlaying state
  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.currentTime = startTime; // Set the start time for playback
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle the mouse down to start dragging
  const handleMouseDown = (e: any, type: any) => {
    e.preventDefault(); // Prevent default to avoid unwanted scrolling
    if (type === "start") {
      setIsDraggingStart(true);
    } else if (type === "end") {
      setIsDraggingEnd(true);
    }
  };

  // Handle mouse movement to drag the slider
  const handleMouseMove = (e: any) => {
    if (isDraggingStart || isDraggingEnd) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const clientX = e.touches ? e.touches[0].clientX : e.clientX; // For touch, get clientX from the first touch
      const percent = (clientX - rect.left) / canvas.width;
      const newTime = percent * duration;

      setIsUseDragEffect(true);
      // Ensure the start time doesn't go past the end time
      if (isDraggingStart) {
        const newStartTime = Math.min(newTime, endTime - 0.1); // Add a buffer of 0.1 seconds to avoid overlap

        setStartTime(Math.max(0, newStartTime)); // Ensure start time isn't less than 0
      } else if (isDraggingEnd) {
        const newEndTime = Math.max(newTime, startTime + 0.1); // Add a buffer of 0.1 seconds to avoid overlap

        setEndTime(Math.min(newEndTime, duration)); // Ensure end time doesn't go beyond the audio duration
      }
    }
  };

  const handleMouseUp = () => {
    setIsDraggingStart(false);
    setIsDraggingEnd(false);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio.currentTime >= endTime) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleAddStartTime = () => {
    setIsUseDragEffect(true);
    if (startTime < 0 || startTime >= endTime - 1) {
      return;
    } else {
      setStartTime((prev) => prev + 1);
    }
  };

  const handleSubtractStartTime = () => {
    setIsUseDragEffect(true);
    if (startTime >= 0.1) {
      setStartTime((prev) => prev - 1);
    }
  };

  const handleAddEndTime = () => {
    setIsUseDragEffect(true);
    if (endTime >= duration) {
      return;
    }
    setEndTime((prev) => prev + 1);
  };

  const handleSubtractEndTime = () => {
    setIsUseDragEffect(true);
    if (startTime < endTime) {
      setEndTime((prev) => prev - 1);
    }
  };

  const audojiValidationSchema = Yup?.object()?.shape({
    lyrics: Yup.string().required("Required"),
  });

  const audojiFormik = useFormik({
    validationSchema: audojiValidationSchema,
    initialValues: {
      lyrics: lyricToEdit || "",
    },
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      // endTime: isUseDragEffect
      // ? Math.trunc(endTime + 0.2)
      // : Math.trunc(endTime),
      let data = {
        startTime: Math.trunc(startTime),
        endTime: Math.trunc(endTime),
        start_m_s_h: formatToTimeString(startTime),
        end_m_s_h: formatToTimeString(endTime),
        lyrics: values?.lyrics,
      };

      console.log("endTime", endTime);

      // console.log("data", data);

      handleSubmitAudoji(data);
    },
  });

  const playActionBtn = (
    <button onClick={handlePlayPause}>
      {isPlaying ? <StopIcon /> : <PlayIcon />}
    </button>
  );

  useEffect(() => {
    if (isRefetchAudoji) {
      audojiFormik?.resetForm();
    }
  }, [isRefetchAudoji]);

  console.log("url>>>", url);

  return url ? (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__title}>{title || ""}</div>

      <audio
        ref={audioRef}
        src={url}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => {
          setDuration(audioRef.current.duration);
        }}
        controls={false}
      />

      <div
        style={{ position: "relative", width: "100%", height: canvasHeight }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        onTouchCancel={handleMouseUp}
      >
        <div className={`${Styles.wrapper__waveBackground} `}>
          <div
            className={`${
              isPlaying && Styles.wrapper__isPlayingwaveBackground
            }`}
          >
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              style={{
                cursor: "pointer",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: `${(startTime / duration) * 100}%`,
            width: `${((endTime - startTime) / duration) * 100}%`,
            height: "100%",
            borderRadius: "15px",
            background:
              "linear-gradient(90deg, rgba(239, 65, 54, 0.6) 0%, rgba(251, 176, 64, 0.7) 100%)",
          }}
        ></div>

        {/* Custom range sliders */}
        <div
          className={`${Styles.wrapper__sliderHandle} ${Styles.wrapper__start}`}
          style={{ left: `${(startTime / duration) * 100}%` }}
          onMouseDown={(e) => handleMouseDown(e, "start")}
          onTouchStart={(e) => handleMouseDown(e, "start")}
        />
        <div
          className={`${Styles.wrapper__sliderHandle} ${Styles.wrapper__end}`}
          style={{ left: `${(endTime / duration) * 100}%` }}
          onMouseDown={(e) => handleMouseDown(e, "end")}
          onTouchStart={(e) => handleMouseDown(e, "end")}
        />
      </div>

      <div className={Styles.wrapper__mainSliderHandle}>
        <div className={Styles.wrapper__mainStartTimeSliderHandle}>
          <div className={Styles.wrapper__mainStartTimeLabel}>
            Start Time: {formatToTimeString(startTime)}
          </div>
          <input
            type="range"
            min="0"
            max={duration}
            value={startTime}
            onChange={(e) => {
              setIsUseDragEffect(false);
              const newStartTime = parseFloat(e.target.value);
              // Ensure start time doesn't go beyond end time
              setStartTime(Math.min(newStartTime, endTime - 0.1));
            }}
          />
        </div>

        <div className={Styles.wrapper__mainEndTimeSliderHandle}>
          <div className={Styles.wrapper__mainEndTimeLabel}>
            End Time: {formatToTimeString(endTime)}
          </div>
          <input
            type="range"
            min="0"
            max={duration}
            value={endTime}
            onChange={(e) => {
              setIsUseDragEffect(false);
              const newEndTime = parseFloat(e.target.value);
              // Ensure end time doesn't go beyond audio duration and start time
              setEndTime(Math.min(newEndTime, duration));
            }}
          />
        </div>
      </div>
      <div className={Styles.wrapper__lyricsContainer}>
        <TextInput
          name={"lyrics"}
          placeholder="Enter Audoji Lyrics"
          value={audojiFormik?.values?.lyrics}
          onChange={audojiFormik.handleChange}
          error={audojiFormik.submitCount > 0 && audojiFormik.errors.lyrics}
        />
      </div>
      <div className={Styles.wrapper__actionsContainer}>
        <div className={Styles.wrapper__actionsForPlayAndControl}>
          <div className={Styles.wrapper__playContainer}>{playActionBtn}</div>
          <div className={Styles.wrapper__inputContainers}>
            <div className={Styles.wrapper__startTimeIncrementAndDecrement}>
              <AudioTrimmerIncrementAndDec
                onAdd={handleAddStartTime}
                onSubtract={handleSubtractStartTime}
                time={formatToTimeString(startTime)}
              />
            </div>
            <div className={Styles.wrapper__endTimeIncrementAndDecrement}>
              <AudioTrimmerIncrementAndDec
                onAdd={handleAddEndTime}
                onSubtract={handleSubtractEndTime}
                time={formatToTimeString(endTime)}
              />
            </div>
          </div>
        </div>
        <div className={Styles.wrapper__playAndCreateBtn}>
          <div className={Styles.wrapper__plaBtnForMobile}>
            <div className={Styles.wrapper__plaInnerBtnForMobile}>
              <button onClick={handlePlayPause}>
                <span>{isPlaying ? "Stop" : "play"}</span>
              </button>
            </div>
          </div>
          <div className={Styles.wrapper__createAudojiBtn}>
            <Button
              title="Create Audoji"
              onClick={audojiFormik?.handleSubmit}
              loading={createAudojiLoader}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default AudioWaveform;
