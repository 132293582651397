/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IappSearchSlice {
  musicsSearch: string;
  audojiSearch: string;
  isClearSearch: boolean;
}

const initialState: IappSearchSlice = {
  musicsSearch: "",
  audojiSearch: "",
  isClearSearch: false,
};

export const appSearchSlice = createSlice({
  name: "appSearchs",
  initialState,
  reducers: {
    setMusicSearch: (state: IappSearchSlice, action: PayloadAction<string>) => {
      const { payload } = action;
      state.musicsSearch = payload;
    },
    setAudojiSearch: (
      state: IappSearchSlice,
      action: PayloadAction<string>,
    ) => {
      const { payload } = action;
      state.audojiSearch = payload;
    },
    setClearSearch: (
      state: IappSearchSlice,
      action: PayloadAction<boolean>,
    ) => {
      const { payload } = action;
      state.isClearSearch = payload;
    },
  },
});

const { actions, reducer } = appSearchSlice;

export const { setMusicSearch, setAudojiSearch, setClearSearch } = actions;

export default reducer;
