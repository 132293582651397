import React from "react";
import styles from "./index.module.scss";
interface IAudioTrimmerIncrementAndDec {
  time: any;
  onAdd: () => void;
  onSubtract: () => void;
}
const AudioTrimmerIncrementAndDec = ({
  onAdd,
  onSubtract,
  time,
}: IAudioTrimmerIncrementAndDec) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__negative} onClick={onSubtract}>
        -
      </div>
      <div className={styles.wrapper__time}>{time}</div>
      <div className={styles.wrapper__addition} onClick={onAdd}>
        +
      </div>
    </div>
  );
};

export default AudioTrimmerIncrementAndDec;
