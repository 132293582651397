// export const formatNumberToString = (seconds: any) => {
//   // Calculate minutes and remaining seconds
//   const minutes = Math.floor(seconds / 60);
//   const remainingSeconds = seconds % 60;

//   // Format the minutes and seconds with leading zeros
//   const formattedMinutes = minutes.toString().padStart(2, "0");
//   const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

//   // Combine into the desired format
//   return `${formattedMinutes}.${formattedSeconds}.0`;
// };

export const formatToTimeString = (input: any) => {
  console.log(input);
  // Extract minutes
  const minutes = Math.floor(input / 60);

  // Extract seconds (remaining part after minutes) and round
  const remainingSeconds = input % 60;
  const seconds = Math.floor(remainingSeconds);

  // Extract milliseconds (fractional part of seconds) and round
  const milliseconds = Math.round((remainingSeconds - seconds) * 1000) % 10;

  // Format the output with leading zeros if necessary
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  const formattedMilliseconds = milliseconds.toString();

  // Return the formatted result
  return `${formattedMinutes}.${formattedSeconds}.${formattedMilliseconds}`;
};
