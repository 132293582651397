import React, { useState, useEffect } from "react";

import SearchIcon from "../../assets/svgs/SearchIcon";

import Styles from "./appSearchInput.module.scss";
interface ISearchInput {
  placeholder: string;

  onChange?: any;
}
const SearchInput = ({ placeholder, onChange }: ISearchInput) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const debounceId = setTimeout(() => {
      onChange(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(debounceId);
    };
  }, [searchTerm]);

  return (
    <div className={Styles.wrapper}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={placeholder || "Search for"}
      />
      <div className={Styles.wrapper__searchIcon}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default SearchInput;
