import React, { useEffect, useMemo, useState } from "react";

import SoundBoardCard from "../../../../components/soundBoardCard";
import UploadCard from "../../../../components/UploadCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/reducers/hooks";
import {
  setIsRefetchAudoji,
  setIsRefetchMusic,
  setIsShowUploadModal,
} from "../../../../redux/reducers/uploadModalSlice";

import Styles from "./MyTopSound.module.scss";
import services from "../../../../controllers";
import MusicSkelenton from "../../../../components/appSkeleton/musicSkelenton";
import UseCustomResizer from "../../../../utilities/hooks/CustomResizer";
import AppEmptyState from "../../../../components/appEmptyState";
import PrevIcon from "../../../../assets/svgs/PrevIcon";
import NextIcon from "../../../../assets/svgs/NextIcon";
import {
  setArtistName,
  setMusicId,
  setMusicImage,
  setMusicTitle,
  setSpotifyLink,
} from "../../../../redux/reducers/songTitle";
import SearchInput from "../../../../components/appSearchInput";
import { setAudojiSearch } from "../../../../redux/reducers/appSearch";
import { useLocation, useNavigate } from "react-router-dom";
import { setMusicEditDetails } from "../../../../redux/reducers/editSongSlice";
import DeleteSongModal from "../../../../modalViews/DeleteSongModal";
import AudioTrimmer from "../AudioTrimmer";
import useGetQueryParams from "../../../../utilities/helpers/useGetQueryParams";
import AppSkeleton from "../../../../components/appSkeleton";
import useToast from "../../../../utilities/hooks/useToast";

// interface ITopSounds {
//   onClickSong: any;
// }
const TopSounds = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const { getQuery } = useGetQueryParams();

  const getSongTitle = getQuery("songTitle");
  const getSongId = getQuery("songId");

  const { isMobile } = UseCustomResizer({ width: 651 });
  const { pathname } = useLocation();
  const { userProfile } = useAppSelector((state) => state?.userProfileDetails);
  const { isRefetchMusic } = useAppSelector((state) => state?.uploadModal);
  const { musicTitle } = useAppSelector((state) => state?.songDetails);
  const { musicsSearch } = useAppSelector((state) => state?.appSearchs);
  const [loader, setLoader] = useState(true);
  const [songdetails, setSongdetails]: any = useState([]);
  const numberOfArrays = isMobile ? [0, 1, 2] : [0, 1, 2, 3, 4, 5];
  const [pageToshow, setPageToShow] = useState(numberOfArrays);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createAudojiLoader, setCreateAudojiLoader] = useState(false);

  const [audioToTrimmer, setAudioToTrimmer] = useState(null);

  const [audioTrimmerLoader, setAudioTrimmerLoader] = useState(false);

  const pushActiveMusicTitle = (
    activeTitle: string,
    activeId: any,
    audioFile: any,
  ) => {
    searchParams.set("songTitle", activeTitle);
    searchParams.set("songId", activeId);
    setAudioToTrimmer(audioFile);
    return navigate(`/dashboard?${searchParams.toString()}`);
  };

  // useEffect(() => {
  //   if (!getSongId && !getSongTitle) {
  //     navigate(`/dashboard?songTitle=&songId=`);
  //   }
  // }, []);

  const getSongs = (userId: string, musicTitleSearch?: any) => {
    setLoader(true);
    let getData = new services().getService(
      `song/all/${userId}?q=${musicTitleSearch || ""}`,
      "isAiBaseUrl",
    );
    getData
      .then((res) => {
        setLoader(false);
        const distructureData = res?.data?.map((list: any) => {
          return {
            ...list,
            audio_file: `${process.env.REACT_APP_FILE_LINK}/${list?.audio_file}`,
            cover_image: `${process.env.REACT_APP_FILE_LINK}/${list?.cover_image}`,
          };
        });
        setSongdetails(distructureData);
        pushActiveMusicTitle(
          distructureData[0]?.title || "",
          distructureData[0]?.id || "",
          distructureData[0]?.audio_file || "",
        );
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (userProfile?._id) {
      getSongs(userProfile?._id);
    }
  }, [userProfile?._id]);

  useEffect(() => {
    if (isRefetchMusic && userProfile?._id) {
      getSongs(userProfile?._id);
    }
    return () => {
      dispatch(setIsRefetchMusic(false));
    };
  }, [userProfile?._id, isRefetchMusic]);

  const handleNext = () => {
    if (pageToshow[numberOfArrays?.length - 1] < songdetails?.length - 1) {
      setPageToShow((prev) => prev.map((list) => list + 1));
    } else {
      setPageToShow(numberOfArrays);
    }
  };

  const handlePrev = () => {
    if (pageToshow[0] !== 0) {
      setPageToShow((prev) => prev.map((list) => list - 1));
    } else {
      setPageToShow(numberOfArrays);
    }
  };

  useEffect(() => {
    setPageToShow(numberOfArrays);
  }, [isMobile]);

  useEffect(() => {
    setInterval(() => {
      setAudioTrimmerLoader(false);
    }, 3000);

    // Cleanup function
    return () => {
      setAudioTrimmerLoader(true);
      // clearInterval(interval); // Clear the interval
    };
  }, [audioToTrimmer]);

  const handleSubmitAudoji = (data: any) => {
    let newData = {
      start_time: data?.startTime,
      end_time: data?.endTime,
      transcription: data?.lyrics,
      audio_file_id: getSongId,
      start_m_s_h: data?.start_m_s_h,
      end_m_s_h: data?.end_m_s_h,
    };

    setCreateAudojiLoader(true);
    let saveUpdateProfile = new services().postService(
      "audoji/create",
      newData,
      "isAiBaseUrl",
    );
    saveUpdateProfile
      .then((res) => {
        setCreateAudojiLoader(false);
        if (res?.status === "success") {
          dispatch(setIsRefetchAudoji(true));
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        setCreateAudojiLoader(false);
        toast.error("an error occurred, try again later.");
      });
  };

  const handleDeleteSong = (list: any, songDetails?: any) => {
    setShowDeleteModal(true);
    if (isMobile) {
      return dispatch(
        setMusicEditDetails({
          ...list,
        }),
      );
    }
    return dispatch(
      setMusicEditDetails({
        ...songDetails[list],
      }),
    );
  };

  const handleUpdateSong = (list: any, songDetails?: any) => {
    dispatch(setIsShowUploadModal(true));
    if (isMobile) {
      return dispatch(
        setMusicEditDetails({
          ...list,
        }),
      );
    }
    return dispatch(
      setMusicEditDetails({
        ...songDetails[list],
      }),
    );
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__titleContainer}>
        <div className={Styles.wrapper__title}>My Music</div>

        <div className={`${Styles.wrapper__searchInput}`}>
          <div className={Styles.wrapper__searchInnerInput}>
            <SearchInput
              placeholder="Search for songs"
              onChange={(value: any) => {
                getSongs(userProfile?._id, value);
              }}
            />
          </div>
        </div>
      </div>
      <div className={"row"}>
        <div
          className={`${Styles.wrapper__cardsContainer} ${songdetails?.length || loader ? "col-xl-9 col-lg-12" : "col-12"}`}
        >
          <div className={Styles.wrapper__cards}>
            {songdetails?.length || loader ? (
              <div>
                {isMobile ? (
                  <div className={Styles.wrapper__soundContainerRowMobile}>
                    {songdetails?.map((list: any, index: number) => {
                      return (
                        <div className={Styles.wrapper__soundContainerMobile}>
                          <div className={Styles.wrapper__soundCardMobile}>
                            <SoundBoardCard
                              handleSelect={(title: string, data: any) => {
                                pushActiveMusicTitle(
                                  list?.title,
                                  list?.id,
                                  list?.audio_file,
                                );
                                // dispatch(setMusicId(list?.id));
                                // dispatch(setMusicTitle(title));
                                // dispatch(
                                //   setMusicImage(list?.cover_image || ""),
                                // );
                                // dispatch(setArtistName(list?.artiste || ""));
                                // dispatch(
                                //   setSpotifyLink(list?.spotify_link || ""),
                                // );
                                // dispatch(setAudojiSearch(""));
                              }}
                              title={list?.title}
                              image={list?.cover_image}
                              key={list?.owner}
                              divIndex={index}
                              selectedSongId={list?.id}
                              selectedSong={getSongId}
                              handleDelete={() => handleDeleteSong(list)}
                              handleEdit={() => handleUpdateSong(list)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={Styles.wrapper__soundBoardWrapper}>
                    {!loader && songdetails?.length > 6 && (
                      <div>
                        <div
                          className={Styles.wrapper__prevCircle}
                          onClick={handlePrev}
                        >
                          <PrevIcon />
                        </div>
                      </div>
                    )}

                    <div className={Styles.wrapper__soundBoardRowContainer}>
                      <div>
                        <div className="row">
                          {pageToshow?.map((list: any, index: number) =>
                            loader ? (
                              <div
                                className={`${Styles.wrapper__soundCards} col-xl-2 col-md-2 col-sm-2 col-xs-4 col-4`}
                                key={index}
                              >
                                <MusicSkelenton />
                              </div>
                            ) : !songdetails[list]?.title ? (
                              ""
                            ) : (
                              <div
                                className={`${Styles.wrapper__soundCards} col-xl-2 col-lg-2 col-sm-2 col-xs-4 col-4`}
                                key={index}
                              >
                                <SoundBoardCard
                                  handleSelect={(title: string, id: any) => {
                                    pushActiveMusicTitle(
                                      title,
                                      id,
                                      songdetails[list]?.audio_file,
                                    );

                                    // dispatch(setMusicId(songdetails[list]?.id));
                                    // dispatch(setMusicTitle(title));
                                    // dispatch(
                                    //   setMusicImage(
                                    //     songdetails[list]?.cover_image || "",
                                    //   ),
                                    // );
                                    // dispatch(
                                    //   setArtistName(
                                    //     songdetails[list]?.artiste || "",
                                    //   ),
                                    // );
                                    // dispatch(
                                    //   setSpotifyLink(
                                    //     songdetails[list]?.spotify_link || "",
                                    //   ),
                                    // );

                                    // dispatch(setAudojiSearch(""));
                                  }}
                                  title={songdetails[list]?.title}
                                  image={songdetails[list]?.cover_image}
                                  key={songdetails[list]?.owner}
                                  divIndex={index}
                                  selectedSongId={songdetails[list]?.id}
                                  dataLength={pageToshow?.length}
                                  selectedSong={getSongId}
                                  handleDelete={() =>
                                    handleDeleteSong(list, songdetails)
                                  }
                                  handleEdit={() =>
                                    handleUpdateSong(list, songdetails)
                                  }
                                />
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </div>
                    {!loader && songdetails?.length > 6 && (
                      <div>
                        <div
                          className={Styles.wrapper__nextCircle}
                          onClick={handleNext}
                        >
                          <NextIcon />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {!loader && !audioTrimmerLoader && audioToTrimmer ? (
                  <div style={{ marginTop: "20px" }}>
                    <AudioTrimmer
                      title="Blinding Lights"
                      url={audioToTrimmer}
                      handleSubmitAudoji={handleSubmitAudoji}
                      createAudojiLoader={createAudojiLoader}
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <AppSkeleton count={1} height={100} />
                  </div>
                )}
              </div>
            ) : (
              <AppEmptyState
                // title="My Music"
                titleMarginBottom="92px"
                onClick={() => dispatch(setIsShowUploadModal(true))}
                btnTitle={!musicsSearch ? "Upload a Song" : ""}
                content={
                  !musicsSearch
                    ? "Welcome to the Soundstage"
                    : "No record fouund."
                }
                subContent={
                  !musicsSearch
                    ? "Welcome to your personal music space! Since you haven't uploaded any songs yet, no audojis have been created. Go ahead and upload your first song and help your fans express their emotions better! "
                    : ""
                }
                height={!musicsSearch ? "" : "60vh"}
              />
            )}
          </div>
        </div>
        {(songdetails?.length || loader) && (
          <div className={`${Styles.wrapper__card} col-xl-3 col-lg-5 col-sm-6`}>
            <UploadCard
              title="Upload song to create audoji"
              // subTitle="This is a short text telling the creator why they should upload a song"
              onClick={() => dispatch(setIsShowUploadModal(true))}
            />
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteSongModal
          onClose={() => {
            setShowDeleteModal(false);
            dispatch(setMusicEditDetails(null));
          }}
        />
      )}
    </div>
  );
};

export default TopSounds;
